<template>
    <!-- 单品作品 -->
    <div class="box">
        <div class="box-head flex">
            <div class="flex-1">
                <el-checkbox @change="selectAll($event)" v-model="allChecked" style="margin-right:20px">全选</el-checkbox>
                <el-button v-if="opt.stat[0]!=4" @click="delSelect('trash')" size="mini" type="danger" plain>批量删除</el-button>
                <el-button v-if="opt.stat[0]==4" @click="delSelect('del')" size="mini" type="danger" plain>批量永久删除</el-button>
                <el-button v-if="opt.stat[0]==4" @click="delSelect('untrash')" size="mini" type="primary" plain>批量还原</el-button>
            </div>
            <div class="flex">
                <el-button v-if="opt.stat[0]!=4" @click="goto('stored/series/edit')" size="mini" type="primary">提交新套系作品</el-button>
                <el-button v-if="opt.stat[0]==4" @click="hideRecycle()" size="mini" type="primary">返回</el-button>
                <el-button @click="recycle()" style="width:76px;color:#808080;" size="mini" type="text">
                    <i class="iconfont icon-icon7" style="font-size:12px;margin-right:4px"></i>回收站</el-button>
            </div>
        </div>
        <div class="box-content" ref="load">
            <div class="list flex">
                <div class="list-item flex" v-for="(item,idx) in list" :key="idx">
                    <div class="checkbox">
                        <el-checkbox @change="selectSingle($event,item)" v-model="item.checked"></el-checkbox>
                    </div>
                    <div @click="gotoDetail(item.id)" class="item-img">
                        <img :src="IMG_URL_PRE+item.photos[0]+'!width_250px'" alt="">
                        <div class="item-img-text">作品详情</div>
                        <div v-if="item.stat==5" class="ir-img flex flex-center">
                            <img src="@/assets/images/desgin/product_stat5.png">
                        </div>
                    </div>
                    <div class="item-info flex-1">
                        <div @click="gotoDetail(item.id)" class="name ellipsis1">
                            <b>{{item.name}}</b>
                        </div>
                        <div class="cat">
                            <span v-if="item.style">{{item.style.name}}</span>
                        </div>
                        <!-- <div class="info-view">
                            <i class="iconfont icon-OvalPathmengban"></i>
                            <span>{{item.view}}个企业可见</span>
                        </div> -->
                        <div class="info-state">
                            <i class="iconfont icon-OvalPathmengban"></i>
                            <span>未申请版权</span>
                            <span>待开放</span>
                        </div>
                        <div class="info-btn">
                            <el-button v-if="item.stat >= 1 && item.stat <= 2" 
                                @click="goto('stored/series/edit?set_id='+item.id)" 
                                size="mini" type="primary" plain>编辑</el-button>
                            <!-- <el-button @click="del()" size="mini" type="danger" plain>删除</el-button> -->
                        </div>
                    </div>
                    <div class="item-right flex flex-center">
                        <div v-if="item.stat==1 || item.stat==5">
                            <div class="price">
                                <b>￥{{item.price | priceFilt}}</b>
                            </div>
                            <div class="ir-type">
                                <span v-if="item.price_type==0">设计版权</span>
                                <span v-if="item.price_type==1">分成合作</span>
                                <span v-if="item.price_type==3">需求定制</span>
                                <span v-if="item.price_type==4">暂不确定类型</span>
                            </div>
                        </div>
                        <div v-if="item.stat>=2 && item.stat<=4" class="ir-img">
                            <img src="@/assets/images/desgin/product_stat2.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="pageShow" style="padding:10px 0;">
            <el-pagination background @current-change="currentChange"
                layout="prev, pager, next"
                :current-page.sync="opt.page"
                :page-size="opt.pagesize"
                :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { seriesList, trashSet, untrashSet, delSet } from '@/service/design';
    export default {
        components: {
            
        },
        props:['query'],
        data() {
            return {
                IMG_URL_PRE,
                STATIC_URL_PRE,
                popVisible: false,
                popTitle: null,
                opt: {
                    k: "", // 搜索关键字
                    style_no: [], // 风格编号列表
                    price_type: [0], // 价格类型，0设计版权销售，3需求定制订单，4暂不确定类型
                    avail_only: 0, // 是否只获取未出售，1是，0否，默认值0
                    stat: [1,2,5], // 产品状态，1可用，2已下架，3已删除，4回收站，5已交易
                    page: 1,
                    pagesize: 16,
                },
                total:0,
                pageShow:false, // 强制刷新分页组件
                list: [],
                allChecked:false, // 全选
                allSelectedId:[], // 全选ID
            }
        },
        watch:{
            // $route(to,from){
            //     if (this.$route.query.page && this.$route.query.page>1) {
            //         this.opt.page=parseInt(this.$route.query.page);
            //     } else this.opt.page = 1;
            //     this.refreshItems();
            //     this.$nextTick(()=>{
            //         this.pageShow = true;
            //     })
            // }
        },
        created() {
            if (this.query.k) {
                this.opt.k = this.query.k;
            }
            if (this.query.style_no) {
                this.opt.style_no = [this.query.style_no];
            }
            if (this.query.cat_id) {
                this.opt.cat_id = [this.query.cat_id];
            }
            if (this.query.price_type) {
                this.opt.price_type = [this.query.price_type];
            }
            if (this.query.avail_only) {
                this.opt.avail_only = 1;
            } else this.opt.avail_only = 0;
            // if (this.$route.query.page && this.$route.query.page>1) {
            //     this.opt.page=parseInt(this.$route.query.page);
            // }
            this.refreshItems();
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        mounted() {},
        methods: {
            goto(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("stored/series/detail/"+id);
            },
            recycle() {
                this.opt.stat = [4];
                this.opt.page = 1;
                this.allSelectedId = [];
                this.refreshItems();
            },
            hideRecycle() {
                this.opt.stat = [1,2,5];
                this.opt.page = 1;
                this.allSelectedId = [];
                this.refreshItems();
            },
            refreshItems() { // 获取数据
                console.log('refreshItems');
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                    target:this.$refs.load
                });
                this.items = [];
                seriesList(this.opt).then(rst => {
                    this.total=rst.total;
                    this.list = rst.list;
                    this.list.forEach((e)=>{
                        this.$set(e,'checked',false);
                        this.allChecked=false;
                    })
                    console.log(this.list);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            currentChange(e) { // 监听分页
                this.opt.page = e;
                // this.$router.push({
                //     query:{ type: this.$route.query.type, page:e}
                // });
                this.$nextTick(()=>{
                    this.pageShow = true;
                })
                this.refreshItems();
            },
            selectSingle(event,item) { //单选
                console.log(event);
                if (event == true) {
                    this.allSelectedId.push(item.id);
                    let length=0;
                    this.list.forEach((e)=>{
                        length+=e.length;
                    })
                    if (this.allSelectedId && this.allSelectedId.length == length) {
                        this.allChecked = true;
                    }
                } else {
                    for (var i = 0; i < this.allSelectedId.length; i++) {
                        if (this.allSelectedId[i] === item.id) {
                            this.allSelectedId.splice(i, 1);
                            this.allChecked = false;
                            break;
                        }
                    }
                }
                // console.log(this.allSelectedId);
            },
            selectAll(event) { //全选
				if(event) {//全选
                    this.allSelectedId = [];
                    this.list.forEach((e)=>{
                        this.$set(e,'checked',true);
                        this.allSelectedId.push(e.id);
                    })
                } else {  //取消全选
                    this.allSelectedId = [];
					this.list.forEach((e)=>{
                        this.$set(e,'checked',false);
                    })
				}
                this.allChecked = event;
                console.log(this.allSelectedId);
			},
            //批量删除
            delSelect(type) {
                let text = null;
                if (type == 'trash') {
                    text = "确认将选中套系批量删除吗?";
                } else if (type == 'del') {
                    text = "确认将选中套系批量永久删除吗?";
                } else if (type == 'untrash') {
                    text = "确认将选中套系批量还原吗?";
                }
                if (this.allSelectedId.length>0) {
                    this.$confirm(text, '温馨提示', { confirmButtonText: '确定',
                    cancelButtonText: '取消', type: 'warning',
                    }).then(() => {
                        this.delet(type);
                    });
                } else {
                    this.$message.error("请选择商品");
                }
            },
            delet(type) {
                if (type == 'trash') {
                    trashSet(this.allSelectedId).then(rst => {
                        console.log('放回收站',rst);
                        this.$message.success("删除成功");
                        this.refreshItems();
                        this.list.forEach((e)=>{
                            if (this.allSelectedId.indexOf(e.id)!==-1) {
                                //从allSelectedId数组中删除
                                let index = this.allSelectedId.indexOf(e.id);
                                this.allSelectedId.splice(index,1);
                            }
                            this.$set(e,'checked',false);
                            this.allChecked=false;
                        })
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else if (type == 'del') {
                    delSet(this.allSelectedId).then(rst => {
                        console.log('delSet',rst);
                        this.$message.success("删除成功");
                        this.refreshItems();
                        this.list.forEach((e)=>{
                            if (this.allSelectedId.indexOf(e.id)!==-1) {
                                //从allSelectedId数组中删除
                                let index = this.allSelectedId.indexOf(e.id);
                                this.allSelectedId.splice(index,1);
                            }
                            this.$set(e,'checked',false);
                            this.allChecked=false;
                        })
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else if (type == 'untrash') {
                    untrashSet(this.allSelectedId).then(rst => {
                        console.log('untrashSet',rst);
                        this.$message.success("还原成功");
                        this.refreshItems();
                        this.list.forEach((e)=>{
                            if (this.allSelectedId.indexOf(e.id)!==-1) {
                                //从allSelectedId数组中删除
                                let index = this.allSelectedId.indexOf(e.id);
                                this.allSelectedId.splice(index,1);
                            }
                            this.$set(e,'checked',false);
                            this.allChecked=false;
                        })
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.box {
    height:100%;
    padding: 10px 20px;
}
.box-head {
    padding-bottom: 10px;
}
.box-content {
    height: calc(100% - 80px);
    overflow: auto;
}
.list-item {
    width: calc(50% - 10px);
    padding: 20px;
    border: 1px solid #E6E6E6;
    margin: 0 20px 20px 0;
    &:nth-child(2n) {
        margin-right: 0;
    }
    .checkbox {
        padding-right: 12px;
    }
    .item-img {
        width: 160px;
        height: 160px;
        position: relative;
        margin-right: 12px;
        &:hover .item-img-text {
            // // fadeIn
            opacity:100;
            filter:alpha(opacity=100);
        }
        .ir-img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            >img {
                width: 98px;
            }
        }
        >img {
            width: 100%;
            height: 100%;
        }
        .item-img-text {
            width: 100%;
            height: 32px;
            line-height: 32px;
            background: rgba(79, 116, 237, 0.6);
            text-align: center;
            font-size: 12px;
            color: #fff;
            position: absolute;
            left: 0; bottom: 0;
            // fadeOut
            -webkit-transition: opacity .2s linear;
            -moz-transition: opacity .2s linear;
            -o-transition: opacity .2s linear;
            transition: opacity .2s linear;
            opacity:0;
            filter:alpha(opacity=0);
        }
    }
    .item-info {
        position: relative;
        border-right: 1px solid #E6E6E6;
        .name {
            font-size: 16px;
            color: #292929;
            line-height: 22px;
            margin-bottom: 4px;
        }
        .cat, .info-view {
            font-size: 12px;
            color: #B3B3B3;
            margin-bottom: 4px;
        }
        .info-view {
            i {
                font-size: 12px;
                margin-right: 5px;
            }
            margin-bottom: 10px;
        }
        .info-state {
            width: 200px;
            font-size: 12px;
            color: #808080;
            padding-top: 10px;
            border-top: 1px solid #E6E6E6;
            i {
                font-size: 12px;
                color: #B3B3B3;
                margin-right: 5px;
            }
            span:last-child {
                color: #B3B3B3;
                margin-left: 4px;
            }
        }
        .info-btn {
            position: absolute;
            left: 0; bottom: 0;
        }
    }
    .item-right {
        width: 160px;
        text-align: center;
        .ir-type {
            font-size: 12px;
            color: #808080;
            margin-top: 4px;
        }
    }
}
</style>